import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from '../../common/NavBar';
import AuthorLogo from "../../assets/img/author.png";
import './index.css';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const BlogsPage = () => {

    const [blogs, setBlogs] = useState([]);
    const [activeBlog, setActiveBlog] = useState({});

    const getRSS = async () => {
        const rssResponse = await axios.get(process.env.REACT_APP_BLOG_URL);
        console.log(rssResponse.data)
        if (rssResponse) {
            setBlogs(rssResponse.data);
            console.log(rssResponse.data)
            setActiveBlog(rssResponse.data[0]);
        }
    }

    useEffect(() => {
        getRSS()
    }, [])

    return (
        <section className="blogs-page">
            <NavBar />
            <div className="blog-page-content has-text-centered">
                <h1 className="title is-2 has-white-text mt-4 mb-5">Blogs</h1>

                <div className="blog-page-column-content">
                    <div className="columns">
                        <div className="column is-4">
                            {
                                blogs.map((item, index) => {
                                    return (
                                        <a onClick={(e) => setActiveBlog(item)} key={index + 1}>
                                            <div className={`card left-blog-card mb-5 ${activeBlog.guid === item.guid && "left-blog-card-active"}`}>
                                                <div className="card-content">
                                                    <div className="columns">
                                                        <div className="column is-one-third is-align-self-center">
                                                            <img className="blog-author" src={AuthorLogo} alt="" />
                                                        </div>
                                                        <div className="column is-twi-thirds is-align-self-center">
                                                            <div className="content has-text-left">
                                                                <h3 className="title is-4 mb-1 has-white-text">{item.title}</h3>
                                                                <p className="has-white-text">{item.pubDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })
                            }

                        </div>
                        <div className="column is-8 ml-3 mr-3">
                            <div className="card right-blog-card" data-aos="zoom-in" data-aos-duration="900">
                                <div className="right-card-text-content has-text-left mt-4">
                                    <h1 className="title is-3 has-white-text mb-2">{activeBlog.title}</h1>
                                    <p className="has-white-text low-opacity-text">{activeBlog.pubDate}</p>

                                    <div className="blog-rich-text mt-5">
                                        <p className="has-text-justified has-white-text">
                                            {
                                                ReactHtmlParser(activeBlog.content)
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-footer mt-6 blog-site-footer">
                    <p className="subtitle is-6 has-white-text low-opacity-text">Designed and Developed by RL © {new Date().toISOString().split('T')[0].split("-")[0]}</p>
                </div>
            </div>
        </section>
    );
}

export default BlogsPage;