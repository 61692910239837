import React, { useState, useEffect } from "react";
import "./index.css";
import axios from "axios";
import NavBar from "../../common/NavBar";
import HomeScoreCard from "./HomeScoreCard";
import sponsorsList from "./data/sponsors";
import ReactPlayer from 'react-player/youtube';
import PassiveScoreCard from "./PassiveScoreCard";
import matchHistory from "./data/history";

const HomePage = () => {

  const [summary, setSummary] = useState();
  const [currentRichmond, setCurrentRichmond] = useState([]);
  const [currentMahinda, setCurrentMahinda] = useState([]);
  const isMatchStarted = process.env.REACT_APP_MATCH_STARTED === "true";
  const isOneDayMatch = process.env.REACT_APP_ONE_DAY_MATCH === "true";

  const getSummary = () => {
    axios
      .get(process.env.REACT_APP_SUMMARY_URL)
      .then((res) => {
        if (res.data) {
          setSummary(res.data);

          //Set current Richmond
          const sortedRichmond = res.data.innings.filter(
            (item) => item.battingTeam === "RCG" || item.battingTeam === "Richmond"
          );

          setCurrentRichmond(sortedRichmond);
          //Set current Mahinda
          const sortedMahinda = res.data.innings.filter(
            (item) => item.battingTeam === "MCG" || item.battingTeam === "Mahinda"
          );
          setCurrentMahinda(sortedMahinda);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCurrentYear = () => {
    return new Date().getFullYear().toString();
  }


  useEffect(() => {
    if (summary) {
      const interval = setInterval(() => {
        getSummary();
      }, 120000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <div className="rl-home-main">
      <NavBar />
      <div className="rl-main-section">
        <div className="columns home-columns">
          <div className="column is-6 home-content-column is-self-align-center">
            <h3 className="title is-5 low-opacity-text has-white-text" data-aos="fade-up" data-aos-duration="900">Richmond - Mahinda Cricket Encounter </h3>
            {/* <button className="button is-primary" onClick={showToast}>Show</button> */}
            <h2 className="title is-1 has-white-text" data-aos="fade-up" data-aos-duration="900">
              Lovers’ Quarrel <span className="rl-heading-year">{getCurrentYear()}</span>
            </h2>
            <h4 className="title is-6 low-opacity-text has-white-text has-text-justified mb-4 mt-6" data-aos="fade-up" data-aos-duration="900">
              The Richmond-Mahinda Cricket Encounter is the Annual Cricket Big
              Match played between the First XI Cricket Teams of Richmond College
              and Mahinda College.
            </h4>
            {isMatchStarted ? (<div className="field is-grouped mt-4" data-aos="zoom-in" data-aos-duration="900">
              <p className="control">
                <a href="https://go.richmondlive.lk/stream" target="_target">
                  <button className="button is-rounded">
                    {/* font awesome play icon */}
                    <i className="fas fa-play mr-2"></i>
                    <p className="home-scorecard-btn-text">WATCH LIVE</p>
                  </button>
                </a>
              </p>
              <p className="control">
                <a href="/scorecard">
                  <button className="button is-rounded home-scorecard-btn">
                    <p className="home-scorecard-btn-text">SCORECARD</p>
                  </button>
                </a>
              </p>
            </div>) : (
              <div className="mt-4">
                <a href="https://richmondlive.lk" target="_target">
                  <button className="button is-rounded">
                    <i className="fas fa-arrow-right mr-2"></i>
                    <p className="home-scorecard-btn-text">Learn more about Richmond Live</p>
                  </button>
                </a>
              </div>
            )}
            {isMatchStarted && <div className="home-hero-bottom">
              <h4 className="title is-6 low-opacity-text has-white-text has-text-justified" data-aos="fade-up" data-aos-duration="900">
                {isOneDayMatch ? "Lovers’ Quarrel One Day Encounter Summary"  : "119th Lovers’ Quarrel Summary"}
              </h4>
              <HomeScoreCard currentRichmond={currentRichmond} currentMahinda={currentMahinda} type="ACTIVE" />
            </div>}
          </div>
          <div className="column is-6 has-text-centered is-align-items-center video-column">
            {
              isMatchStarted ?
                (<div className="video-holder" data-aos="zoom-in" data-aos-duration="900">
                  {summary && <ReactPlayer className="react-player" url={summary?.streamURL} width='100%' height='100%' />}
                </div>) :
                (<div className="match-history has-text-left">
                  <h2 className="title is-4 has-white-text" data-aos="fade-left" data-aos-duration="900">
                    Previous Encounters
                  </h2>
                  {
                    matchHistory.map((item, index) => {
                      return <PassiveScoreCard
                        year={item.year}
                        firstBattingTeam={item.firstBattingTeam}
                        winningTeam={item.winner}
                        firstBattingTeamInnings1={item.firstBattingTeamInnings1}
                        firstBattingTeamInnings2={item.firstBattingTeamInnings2}
                        secondBattingTeamInnings1={item.secondBattingTeamInnings1}
                        secondBattingTeamInnings2={item.secondBattingTeamInnings2}
                        summaryLine={item.summaryLine}
                        key={index}
                      />
                    }
                    )
                  }
                </div>)}
          </div>
        </div>
      </div>
      <div className="sponsors-section">
        <h1 className="title is-1 has-white-text mb-3" data-aos="fade-up" data-aos-duration="900">Sponsors</h1>
        <h1 className="title is-5 low-opacity-text has-white-text mb-6" data-aos="fade-up" data-aos-duration="900">Sponsors of the Lovers’ Quarrel {getCurrentYear()}</h1>
        <div className="sponsor-grid">
          {
            sponsorsList.map((item, index) => {
              return <a href={item.url} target="_blank">
                <div className="card sponsor-card" key={index} data-aos="zoom-in" data-aos-duration="900">
                  {
                    item.category === "gold" && <div className={`top-right ${item.category}-sponsor`}><p>Gold Sponsor</p></div>
                  }
                  {
                    item.category === "platinum" && <div className={`top-right ${item.category}-sponsor`}><p>Platinum Sponsor</p></div>
                  }
                  {
                    item.category === "silver" && <div className={`top-right ${item.category}-sponsor`}><p>Silver Sponsor</p></div>
                  }
                  {
                    item.category === "bronze" && <div className={`top-right ${item.category}-sponsor`}><p>Bronze Sponsor</p></div>
                  }
                  {
                    item.category === "broadcast" && <div className={`top-right ${item.category}-sponsor`}><p>Broadcast Partner</p></div>
                  }
                  <img className="sponsor-img" src={item.logo} alt="" />
                  <h1 className="title is-4 mt-3 has-text-white">{item.company}</h1>
                </div>
              </a>
            })
          }
        </div>
      </div>
      <div className="site-footer">
        <p className="subtitle is-6 has-white-text low-opacity-text">Designed and Developed by RL © {getCurrentYear()}</p>
      </div>
    </div>
  );
};

export default HomePage;
