
import ABLogo from "../../../assets/img/sponsors/angelbeach.png";
import HNBALogo from "../../../assets/img/sponsors/hnba.png";
import PeoplesBankLogo from "../../../assets/img/sponsors/peoplesbank.png";
import ThePapareLogo from "../../../assets/img/sponsors/thepapare.png";
import HayleysSolarLogo from "../../../assets/img/sponsors/hayleyssolar.png";
import RathnaLogo from "../../../assets/img/sponsors/rathna.png";
import MaggieLogo from "../../../assets/img/sponsors/maggie.jpg";

const sponsorsList = [
    {
        "company": "The Papare",
        "logo": ThePapareLogo,
        "category": "broadcast",
        "url": "https://live.thepapare.com/"
    },
    {
        "company": "Angel Beach",
        "logo": ABLogo,
        "category": "small",
        "url": "https://angelbeachclub.com/"
    },
    {
        "company": "HNB Assurance",
        "logo": HNBALogo,
        "category": "small",
        "url": "https://www.hnbassurance.com/"
    },
    {
        "company": "People's Bank",
        "logo": PeoplesBankLogo,
        "category": "small",
        "url": "https://www.peoplesbank.lk/"
    },
    {
        "company": "Hayleys Solar",
        "logo": HayleysSolarLogo,
        "category": "small",
        "url": "https://www.hayleyssolar.com/"
    },
    {
        "company": "Rathna Furniture",
        "logo": RathnaLogo,
        "category": "small",
        "url": ""
    },
    {
        "company": "Maggie Papare Kottu",
        "logo": MaggieLogo,
        "category": "small",
        "url": "https://www.maggielanka.com/"
    }
];

export default sponsorsList;