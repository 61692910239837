import React from "react";
import "./index.css";

const BowlingCard = ({ bowlers }) => {
  return (
    <div className="accordian-content-wrapper mt-5">
      <div className="columns title-column is-mobile is-narrow">
        <div className="column is-8">
          <h1 className="title is-6 has-white-text">Bowler</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">O</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">R</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">W</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">Eco</h1>
        </div>
      </div>

      {bowlers &&
        bowlers.map((bowler, index) => {
          return (
            <>
              <div className="columns bowling-card-content is-mobile" key={index}>
                <div className="column is-8">
                  <h1 className="title is-6 has-white-text">{bowler.name}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{bowler.overs}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{bowler.runs}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{bowler.wickets}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{((bowler.runs * 6) / bowler.balls).toFixed(1)}</h1>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default BowlingCard;
