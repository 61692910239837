import React from 'react';
import RichmondLogo from "../../assets/img/richmond_college_crest 1.png";
import MahindaLogo from "../../assets/img/MahindaCollegeCrest 1.png";
import './index.css';

const HomeScoreCard = ({
    currentRichmond,
    currentMahinda
}) => {
    return (
        <div className="card home-scorecard" data-aos="fade-up" data-aos-duration="900">
            <div className="score-card-content">
                <div className="columns is-mobile">
                    <div className="column is-6 is-narrow">
                        <div className="columns is-mobile">
                            <div className="column is-one-third is-narrow is-align-self-center	">
                                <div className="home-crest-container">
                                    <img className="home-scorecard-logo" src={RichmondLogo} alt="" data-aos="zoom-in" data-aos-duration="900" />
                                </div>
                            </div>
                            <div className="column is-two-thirds is-narrow is-align-self-center	">
                                <div className="left-content">
                                    <h6 className="title is-6 low-opacity-text has-white-text has-text-justified mb-1">Richmond</h6>
                                    <h5 className="title is-5 has-white-text has-text-justified mb-1">
                                        {currentRichmond[0]?.score || "0/0"}{" "}
                                        {currentRichmond[1] ? "&" : ""}{" "}
                                        {currentRichmond[1]?.score || ""}
                                    </h5>
                                    <h6 className="title is-6 low-opacity-text has-white-text has-text-justified">
                                        {currentRichmond.length >= 0 &&
                                            (currentRichmond[currentRichmond.length - 1]?.overs ||
                                                "0/0")}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-6 is-narrow">
                        <div className="columns is-mobile">
                            <div className="column is-one-third is-narrow is-align-self-center">
                                <div className="home-crest-container">
                                    <img className="home-scorecard-logo" src={MahindaLogo} alt="" data-aos="zoom-in" data-aos-duration="900" />
                                </div>
                            </div>
                            <div className="column is-two-thirds is-narrow is-align-self-center	">
                                <div className="left-content">
                                    <h6 className="title is-6 low-opacity-text has-white-text has-text-justified mb-1">Mahinda</h6>
                                    <h5 className="title is-5 has-white-text has-text-justified mb-1">
                                        {currentMahinda[0]?.score || "0/0"}{" "}
                                        {currentMahinda[1] ? "&" : ""}{" "}
                                        {currentMahinda[1]?.score || ""}
                                    </h5>
                                    <h6 className="title is-6 low-opacity-text has-white-text has-text-justified">
                                        {currentMahinda.length >= 0 &&
                                            (currentMahinda[currentMahinda.length - 1]?.overs || "0/0")}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeScoreCard;