import React, { useState, useEffect } from "react";
import RichmondLogo from "../../../../assets/img/richmond_college_crest 1.png";
import MahindaLogo from "../../../../assets/img/MahindaCollegeCrest 1.png";
import "./index.css";
import axios from "axios";

const ScoreBlock = () => {
  const [summary, setSummary] = useState();
  const [currentRichmond, setCurrentRichmond] = useState([]);
  const [currentMahinda, setCurrentMahinda] = useState([]);

  const getSummary = () => {
    axios
      .get(process.env.REACT_APP_SUMMARY_URL)
      .then((res) => {
        if (res.data) {
          //console.log(res.data);
          setSummary(res.data);

          //Set current Richmond
          const sortedRichmond = res.data.innings.filter(
            (item) =>
              item.battingTeam === "Richmond" || item.battingTeam === "RCG"
          );
          //console.log("sortedRichmond", sortedRichmond);
          setCurrentRichmond(sortedRichmond);
          //Set current Mahinda
          const sortedMahinda = res.data.innings.filter(
            (item) =>
              item.battingTeam === "Mahinda" || item.battingTeam === "MCG"
          );
          setCurrentMahinda(sortedMahinda);
          //console.log("sortedMahinda", sortedMahinda)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSummary();
  }, []);

  return (
    <div
      className="card main-score-container has-blue-background"
      data-aos="zoom-in"
      data-aos-duration="900"
    >
      <div className="card-content main-score-container-content">
        <div className="columns is-mobile">
          <div className="column is-6">
            <div className="columns is-mobile">
              <div className="column is-3 has-text-right is-align-self-center">
                <img
                  className="richmond-logo-scorecard"
                  src={RichmondLogo}
                  alt=""
                />
                <div className="color-block has-richmond-blue-background"></div>
              </div>
              <div className="column is-9 has-text-left is-align-self-center">
                <h1 className="title has-white-text is-5 mb-1">Richmond</h1>
                <h1 className="title has-white-text is-2 mt-1 mb-1">
                  {currentRichmond[0]?.score || "0/0"}{" "}
                  {currentRichmond[1] ? "&" : ""}{" "}
                  {currentRichmond[1]?.score || ""}
                </h1>
                <h1 className="title has-white-text is-5 mb-1 low-opacity-text">
                  {currentRichmond.length >= 0 &&
                    (currentRichmond[currentRichmond.length - 1]?.overs ||
                      "0/0")}
                </h1>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="columns is-mobile">
              <div className="column is-9 has-text-right is-align-self-center">
                <h1 className="title has-white-text is-5 mb-1">Mahinda</h1>
                <h1 className="title has-white-text is-2 mt-1 mb-1">
                  {currentMahinda[0]?.score || "0/0"}{" "}
                  {currentMahinda[1] ? "&" : ""}{" "}
                  {currentMahinda[1]?.score || ""}
                </h1>
                <h1 className="title has-white-text is-5 mb-1 low-opacity-text">
                  {currentMahinda.length >= 0 &&
                    (currentMahinda[currentMahinda.length - 1]?.overs || "0/0")}
                </h1>
              </div>
              <div className="column is-3 has-text-left is-align-self-center">
                <img
                  className="richmond-logo-scorecard"
                  src={MahindaLogo}
                  alt=""
                />
                <div className="color-block has-gold-background"></div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="status-bar">
          <div className="columns is-narrow is-mobile">
            <div className="column is-6 has-text-left">
              <h1 className="title is-6 has-white-text">
                {summary?.string || "117th Lovers' Quarrel 2022"}
              </h1>
            </div>
            {summary?.appSettings.isLive && (
              <div className="column is-6 has-text-right">
                <h1 className="title is-5 live-text">Live</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreBlock;
