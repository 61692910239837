import React from 'react';
import './index.css';

const AdBanner = ({ closeToast, toastProps, image, url }) => {
    return (
        <div className="ad-card">
            <a href={url}>
                <img src={image} alt="" />
            </a>
        </div>
    );
}

export default AdBanner;