import React from "react";
import "./index.css";

const BattingCard = ({ batters, score, overs, rr }) => {
  return (
    <div className="accordian-content-wrapper">

      <div className="columns title-column is-mobile">
        <div className="column is-4">
          <h1 className="title is-6 has-white-text">Batter</h1>
        </div>
        <div className="column is-3"></div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">R</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">B</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">4s</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">6s</h1>
        </div>
        <div className="column is-1">
          <h1 className="title is-6 has-white-text has-text-centered">SR</h1>
        </div>
      </div>

      {batters &&
        batters.map((batter, index) => {
          return (
            <section className="singe-inning-card" key={index}>
              <div className="columns is-mobile">
                <div className="column is-4">
                  <h1 className="title is-6 has-white-text">
                    {batter.name ?? ""}
                    {batter.isCurrent ? (
                      <span className="rl-current-player">*</span>
                    ) : (
                      ""
                    )}
                  </h1>
                </div>
                <div className="column is-3">
                  <h1 className="title is-6 has-white-text">{batter.dismissalString}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{batter.runs}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{batter.balls}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{batter.fours}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{batter.sixes}</h1>
                </div>
                <div className="column is-1">
                  <h1 className="title is-6 has-white-text has-text-centered">{batter.sr}</h1>
                </div>
              </div>
            </section>
          );
        })}
      <hr />
      <div className="innings-final-scores">
        <div className="columns is-mobile">
          <div className="column is-8">
            <h1 className="title is-6 has-white-text">Total</h1>
          </div>
          <div className="column is-2">
            <h1 className="title is-6 has-white-text has-text-right"> {score ?? ""}</h1>
          </div>
          <div className="column is-2">
            <h1 className="title is-6 has-white-text has-text-right">{overs} ov (RR {rr})</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BattingCard;
